<template>
  <div ref="chesstable_tbl" class="body">
    <TurnWindow
      :showFlag="showFlag"
      :turn="turn"
      :people="people"
      :jobs="jobs"
      v-on:hide="windowTurnClose"
    />
    <table class="t_table" id="table_main">
      <tr>
        <th class="t_date"></th>
        <template v-for="(item, idx) in HOURS">
          <th v-if="item" :key="idx" class="t_cell t_cell_header">
            {{ item }}
          </th>
        </template>
      </tr>

      <template v-for="(row, y) in tbl">
        <tr v-if="!row[row.length - 1]" :key="y + 'dd'">
          <td :colspan="HOUR_END - HOUR_START + 2" class="day-divider"></td>
        </tr>
        <!-- turn row divider, y + date -->
        <tr :key="y + row[row.length - 2]">
          <td
            class="t_date"
            :class="{
              upperborder: !row[row.length - 1],
              t_date_current: isToday(row[row.length - 2]),
            }"
            ref="chesstable_date"
          >
            <div
              class="href"
              v-if="row[row.length - 1] === 0"
              @click.stop="addRowIntoTable(row[row.length - 2])"
              title="Добавить строку"
            >
              {{
                row[row.length - 1] === 0 ? dateFormatHuman(row[row.length - 2], true) : spacer
              }}
            </div>
          </td>

          <template v-for="(item, x) in HOURS">
            <td
              v-if="item"
              :key="x"
              :title="item"
              :class="{
                selectedadd: row[x] === null,
              }"
              :data-row="y"
              :data-col="x"
              :ref="x == HOURS[HOUR_START] ? 'chesstable_cell' : null"
            ></td>
          </template>
        </tr>
      </template>
    </table>
    <Turn
      v-on:openEdit="windowTurnShow"
      v-on:highLight="highLight"
      :TURNS="TURNS"
      :people="people"
      :jobs="jobs"
      :date_begin_local="date_begin_local"
      :key="updateTrigger"
    />
    <Debug v-if="DEBUG" :showPeople="showPeople" :showTurns="showTurns" :showTbl="showTbl" />
  </div>
</template>
<script>
import TurnWindow from "@/components/TurnWindow";
import Turn from "@/components/Turn";
import Debug from "@/components/Debug";
import { dateFormatHuman } from "@/components-js/dateFormat";
import { DEBUG, HOURS, HOUR_START, HOUR_END } from "@/config/settings";
// import { rolesGet } from "@/components-js/requestSrv";

export default {
  name: "ChessItself",
  props: {
    autoTable: Boolean,
    tbl: Array,
    TURNS: Object,
    people: Array,
    jobs: Array,
    date_begin: String,
    id_point: [Number, String],
    turnDel: Number,
  },
  components: {
    TurnWindow,
    Turn,
    Debug,
  },
  data() {
    return {
      showFlag: false,
      spacer: "\u00A0",
      // cell selection
      colSelected: null,
      rowSelected: null,
      markFlag: 0,
      date_begin_local: null,
      updateTrigger: 0,
      DEBUG,
      HOURS,
      HOUR_START,
      HOUR_END,

      ROLES: [],

      // cell selection
      turn: {
        id_turn: null,
        id_point: null,
        id_people: null,
        id_job: null,
        turn_date: null,
        hour_begin: null,
        hour_end: null,
        time_add: null,
        time_add_note: null,
        turn_order: null,
        turn_rate: null,
        orderAbs: null,
        status: null,
        turn_type: null,
        turn_note: null,
      },
    };
  },

  created() {
    window.addEventListener("resize", () => {
      this.updateTrigger++;
    });
  },

  async mounted() {
    // set mouse event handlers
    const elem = document.getElementById("table_main");
    // because main table can be absent
    if (elem && !this.autoTable) {
      elem.addEventListener("mousedown", this.selectPress);
    } else console.log("table_main NOT FOUND");

    // local copy of date_begin
    this.date_begin_local = this.date_begin;

    // ROLES ACCESS
    //    this.ROLES = await rolesGet();
    this.ROLES = this.$root.user.roles;
  },

  methods: {
    /* DEBUG */
    showPeople() {
      this.people.forEach((el) => console.log({ ...el }));
    },
    showTurns() {
      console.log(this.TURNS.getList());
    },
    showTbl() {
      console.log(this.tbl);
    },
    /* DEBUG */
    dateFormatHuman(p, w) {
      return dateFormatHuman(p, w);
    },

    isToday(d) {
      return dateFormatHuman(new Date()) == dateFormatHuman(d);
    },

    // add row into table
    addRowIntoTable(date) {
      // ROLES ACCESS
      // if (!this.ROLES.includes("admin")) {
      //   // TURNS.log("denied");
      //   return;
      // }

      let lastOrder = 0;
      let idx = 0;

      // new row
      const addTbl = [];

      // fill new row with zeros
      for (let k = HOUR_START; k <= HOUR_END; k++) addTbl[k] = 0;

      // add date at the end of new row
      addTbl.push(date);

      // get turns order at seleted date
      for (let x = 0; x < this.tbl.length; x++) {
        if (this.tbl[x][this.tbl[x].length - 2] === date) {
          lastOrder = this.tbl[x][this.tbl[x].length - 1];
          idx = x;
        }
      }
      // after last position
      lastOrder++;
      idx++;

      // add increased order at the end of new row
      addTbl.push(lastOrder);

      // insert new row
      this.tbl.splice(idx, 0, addTbl);
      this.TURNS.changeOrderAbs(date, 1);

      this.updateTrigger++;
    },

    // ---------------------------
    // table row selection start
    // ---------------------------
    selectPress(evt) {
      //console.log("selectPress");
      // handle left mouse button only

      // if (!this.ROLES.includes("admin")) {
      //   // console.log("denied");
      //   return;
      // }

      if (evt.which != 1) {
        //cancelMark();
        return;
      }
      // mark mode just begin
      if (this.markFlag === 0) {
        // if the cell is not availiable for mark - exit
        if (!evt.target.hasAttribute("data-col") || !evt.target.hasAttribute("data-row")) {
          //console.log("non-usable cell");
          this.resetTurn();
          return;
        }

        // BEGIN HOUR has been selected
        this.markFlag = 1;

        // remeber selected row and col
        this.rowSelected = parseInt(evt.target.getAttribute("data-row"));
        this.colSelected = parseInt(evt.target.getAttribute("data-col"));

        // mark tbl cell
        this.tbl[this.rowSelected][this.colSelected] = null; // NaN -1
        this.$forceUpdate();

        this.resetTurn();

        // remember date
        this.turn.turn_date = this.tbl[this.rowSelected][this.tbl[this.rowSelected].length - 2];
        // remember begin hour
        this.turn.hour_begin = parseInt(evt.target.getAttribute("data-col"));

        // remember turn order
        this.turn.turn_order =
          this.tbl[this.rowSelected][this.tbl[this.rowSelected].length - 1];

        // remember orderAbs
        this.turn.orderAbs = this.rowSelected;

        return;
      } else {
        // mark mode second click
        if (
          // click not the same row, as at the begin
          parseInt(evt.target.getAttribute("data-row")) != this.rowSelected ||
          // click on busy cell
          this.tbl[parseInt(evt.target.getAttribute("data-row"))][
            parseInt(evt.target.getAttribute("data-col"))
          ] > 0 ||
          // click on cell behind another turn
          !this.checkTurn(
            undefined,
            parseInt(evt.target.getAttribute("data-col")),
            parseInt(evt.target.getAttribute("data-row"))
          )
        ) {
          // console.log("wrong cell, FORCE END");
          this.tbl[this.rowSelected][this.colSelected] = 0;
          // check for updateTrigger++
          this.$forceUpdate();
          this.resetTurn();
          this.markFlag = 0;
          return;
        }
        // END HOUR has been selected
        this.turn.hour_end = parseInt(evt.target.getAttribute("data-col"));
        // reverse if END less than BEGIN
        if (this.turn.hour_begin > this.turn.hour_end) {
          this.turn.hour_end += this.turn.hour_begin;
          this.turn.hour_begin = this.turn.hour_end - this.turn.hour_begin;
          this.turn.hour_end -= this.turn.hour_begin;
        }

        // set id_point
        this.turn.id_point = parseInt(this.id_point);

        this.markFlag = 0;
        // UNmark tbl cell
        this.tbl[this.rowSelected][this.colSelected] = 0;

        // open window with turn acception
        this.windowTurnShow();
      }
    },

    resetTurn() {
      this.turn.id_turn = null;
      this.turn.id_point = null;
      this.turn.id_people = null;
      this.turn.id_job = null;
      this.turn.turn_date = null;
      this.turn.hour_begin = null;
      this.turn.hour_end = null;
      this.turn.time_add = 0;
      this.turn.time_add_note = null;
      this.turn.turn_order = null;
      this.turn.turn_rate = 0;
      this.turn.orderAbs = null;
      this.turn.turn_note = null;
    },

    checkTurn(a = this.turn.hour_begin, b = this.turn.hour_end, y = this.rowSelected) {
      // check interval between to hours, for the absence of turn inside
      // select check direction
      const d = a < b ? 1 : -1;
      // console.log("check between " + a + " and " + b + " step " + d);

      for (let i = a; i != b; i += d) {
        if (this.tbl[y][i] > 0) return false;
      }

      return true;
    },

    highLight(id = null) {
      if (!id) return;
      this.TURNS.highlightGroup(id);
      this.updateTrigger++;
    },

    // show turn window
    windowTurnShow(id = null) {
      if (this.autoTable) return;
      // ROLES ACCESS
      // if (!this.ROLES.includes("admin")) {
      //   // console.log("denied");
      //   this.TURNS.highlightGroup(id);
      //   return;
      // }

      // user can access own turns only
      if (id && !this.ROLES.includes("admin")) {
        if (this.TURNS.getPeople(id) != this.$root.user.uid) return;
      }

      if (id) {
        // turn selected
        this.turn.id_turn = id;
        this.turn.id_point = this.TURNS.getPoint(id);
        this.turn.id_people = this.TURNS.getPeople(id);
        this.turn.id_job = this.TURNS.getJob(id);
        this.turn.turn_date = this.TURNS.getDate(id);
        this.turn.hour_begin = this.TURNS.getHourBegin(id);
        this.turn.time_add = this.TURNS.getTimeAdd(id);
        this.turn.time_add_note = this.TURNS.getTimeAddNote(id);
        this.turn.hour_end = this.TURNS.getHourEnd(id);
        this.turn.turn_order = this.TURNS.getOrder(id);
        this.turn.turn_rate = this.TURNS.getRate(id);
        this.turn.orderAbs = this.TURNS.getOrderAbs(id);
        this.turn.status = this.TURNS.getStatus(id);
        this.turn.turn_type = this.TURNS.getType(id);
        this.turn.turn_note = this.TURNS.getNote(id);
      } else {
        // new turn
        this.turn.id_people = this.$root.user.uid;
      }
      this.showFlag = true;
    },

    // created turn from add window and what to do
    windowTurnClose(turn, action) {
      // turn is Object, passed by ref

      // if point is the same, then update instance of turn in turns[]
      if (action == "add" && this.id_point == turn.id_point) {
        // add new turn into turns[]
        // this.turns[turn.id_turn] = new Object();
        // Object.assign(this.turns[turn.id_turn], turn);
        this.TURNS.add(turn);
        for (let i = turn.hour_begin; i <= turn.hour_end; i++)
          this.tbl[this.rowSelected][i] = turn.id_turn;
        this.TURNS.highLightOwnTurns();
      }
      if (action == "upd") {
        // unpaint old instance of turn
        for (
          let k = this.TURNS.getHourBegin(turn.id_turn);
          k <= this.TURNS.getHourEnd(turn.id_turn);
          k++
        )
          this.tbl[this.TURNS.getOrderAbs(turn.id_turn)][k] = 0;
        // if point is the same, then update instance in turns[]
        if (this.id_point == turn.id_point) {
          // Object.assign(this.turns[turn.id_turn], this.turn);
          this.TURNS.update(turn);
          // paint new instance of turn
          for (let i = parseInt(turn.hour_begin); i <= parseInt(turn.hour_end); i++) {
            this.tbl[this.TURNS.getOrderAbs(turn.id_turn)][i] = turn.id_turn;
          }
          this.TURNS.highLightOwnTurns();
        } else {
          // point changed, delete turn
          this.TURNS.delete(turn.id_turn);
        }
      }
      if (action === "del") {
        // delete success, so
        // 1) remove from tbl array
        for (
          let k = this.TURNS.getHourBegin(turn.id_turn);
          k <= this.TURNS.getHourEnd(turn.id_turn);
          k++
        )
          this.tbl[this.TURNS.getOrderAbs(turn.id_turn)][k] = 0;
        // 2) erase in turns array
        this.TURNS.delete(turn.id_turn);
      }
      this.updateTrigger++;
      this.showFlag = false;
    },
  },
};
</script>
