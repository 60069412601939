<template>
  <label>
    {{ label }}
    <select
      @change="$emit('change', $event.target.value)"
      ref="hour"
      :value="hour"
      class="modalMobile"
    >
      <option v-for="el in HOURSADD" :value="el" :key="el">{{ el }}</option>
    </select>
  </label>
</template>

<script>
import { HOURSADD } from "@/config/settings";

export default {
  name: "HourSelectorAdd",
  props: { label: String, hour: Number },
  data: function () {
    return {
      HOURSADD,
    };
  },
};
</script>
