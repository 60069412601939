<template>
  <div v-show="showFlag">
    <section class="modalTurn">
      <div class="modalTurn-content">
        <div class="modalWin">
          <h3 v-if="turn.id_turn" class="modalMobile">Редактировать сеанс</h3>
          <h3 v-else class="modalMobile">Добавить сеанс</h3>
          <div class="flexR center modalMobile">
            {{ dateFormatHuman(turn.turn_date, "w") }}
          </div>
          <div class="flexR center">
            <PointSelector
              label=""
              :id_pointIn="turn.id_point"
              @change="turn.id_point = parseInt($event)"
            />
          </div>
          <br />
          <div v-if="this.$root.user.roles && this.$root.user.roles.includes('admin')">
            <br />
            <div class="modalMobile">{{ this.$root.user.nickname }}</div>
            <br />
          </div>
          <div class="flexR center modalMobile">
            <button class="btn" @click="turnTypeRotate">
              {{
                turn.turn_type == TURN_TYPE_ADVICE
                  ? TURN_TYPE_ADVICE_CAPTION
                  : TURN_TYPE_SESSION_CAPTION
              }}
            </button>
          </div>
          <br />
          <div v-show="this.$root.user.roles && this.$root.user.roles.includes('admin')">
            <div ref="human" class="flexR center">
              <PeopleSelector
                label=""
                filter="work"
                fontSize="large"
                :id_peopleIn="turn.id_people"
                @change="peopleSelectorHandler(parseInt($event))"
              />
            </div>
            <br />
            <div v-show="false">
              <select v-model="turn.id_job">
                <option v-for="el in jobs" :value="el.id" :key="el.id">
                  {{ el.job_name }}
                </option>
              </select>
            </div>
            <!-- <div ref="type" style="width: fit-content; display: inline-flex">
              <TypeSelector
                label=""
                filter="work"
                fontSize="large"
                :id_peopleIn="turn.id_people"
                @change="peopleSelectorHandler(parseInt($event))"
              />
            </div> -->
          </div>
          <br />
          <div class="flexR center modalMobile">
            Стоимость &nbsp;
            <input v-model.number="turn.turn_rate" class="num modalMobile modalNumWidth" />
          </div>

          <div class="err">{{ errMessage }}&nbsp;</div>
          <br />
          <div ref="hours" style="display: inline-block; padding: 4px">
            <HourSelector
              label="Час нач."
              :offset="0"
              :hour="turn.hour_begin"
              @change="turn.hour_begin = parseInt($event)"
            />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <HourSelector
              label="Час кон."
              :offset="1"
              :hour="turn.hour_end"
              @change="turn.hour_end = parseInt($event)"
            />
          </div>
          <br />
          <br />
          <br />
          <div class="flexR center">Комментарий</div>
          <div class="flexR center">
            <textarea
              class="txt-area modalMobile"
              placeholder="Ваши каменты"
              v-model="turn.turn_note"
            ></textarea>
          </div>
          <br />
          <br />
          <div class="flexR center modalMobile">
            Доп.часы&nbsp;
            <input type="checkbox" v-model="timeAddFlag" :checked="timeAddFlag" />
          </div>
          <div v-show="timeAddFlag">
            <div class="flexR center">
              <HourSelectorAdd
                :hour="turn.time_add"
                @change="turn.time_add = parseInt($event)"
              />
              &nbsp;
              <textarea v-model="turn.time_add_note" class="txt-area"></textarea>
            </div>
            <br />
            <br />
          </div>
          <br />
          <button v-if="turn.id_turn" v-on:click="turnDelete()" class="btn warn">
            <div class="modalMobile">Удалить</div>
          </button>
          &nbsp;
          <button v-on:click="windowClose(0, null)" class="btn cancel">
            <div class="modalMobile">Отмена</div>
          </button>
          &nbsp;
          <button ref="applyButton" v-on:click="Apply()" class="btn">
            <div class="modalMobile">{{ turn.id_turn ? "Применить" : "Добавить" }}</div>
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
.num {
  text-align: center;
}
</style>

<script>
import PointSelector from "@/components/PointSelector";
import PeopleSelector from "@/components/PeopleSelector.vue";
// import TypeSelector from "@/components/TypeSelector.vue";
import HourSelector from "@/components/HourSelector";
import HourSelectorAdd from "@/components/HourSelectorAdd";
import { dateFormatHuman } from "@/components-js/dateFormat";
import { request } from "@/components-js/requestSrv";
import {
  TURN_CLOSE_VAL,
  TURN_CLOSE_VAL_CAPTION,
  TURN_FREE_VAL,
  TURN_FREE_VAL_CAPTION,
  TURN_TYPE_ADVICE,
  TURN_TYPE_ADVICE_CAPTION,
  TURN_TYPE_SESSION,
  TURN_TYPE_SESSION_CAPTION,
} from "@/config/settings";

export default {
  name: "TurnWindow",
  components: {
    PointSelector,
    PeopleSelector /*, TypeSelector*/,
    HourSelector,
    HourSelectorAdd,
  },
  props: {
    showFlag: Boolean,
    turn: Object,
    people: Array,
    jobs: Array,
  },

  data() {
    return {
      errMessage: null,
      TURN_CLOSE_VAL: TURN_CLOSE_VAL,
      TURN_CLOSE_VAL_CAPTION: TURN_CLOSE_VAL_CAPTION,
      TURN_FREE_VAL: TURN_FREE_VAL,
      TURN_FREE_VAL_CAPTION: TURN_FREE_VAL_CAPTION,
      TURN_TYPE_ADVICE: TURN_TYPE_ADVICE,
      TURN_TYPE_ADVICE_CAPTION: TURN_TYPE_ADVICE_CAPTION,
      TURN_TYPE_SESSION: TURN_TYPE_SESSION,
      TURN_TYPE_SESSION_CAPTION: TURN_TYPE_SESSION_CAPTION,
      timeAddFlag: false,
    };
  },

  watch: {
    showFlag: function (newVal) {
      // window appeared, set default values
      if (newVal) {
        document.addEventListener("keyup", this.keyPressHandler);
        // time_add controls
        this.turn.time_add ? (this.timeAddFlag = true) : (this.timeAddFlag = false);

        this.turn.status = TURN_CLOSE_VAL;
        if (this.turn.turn_type == null) this.turn.turn_type = TURN_TYPE_ADVICE;

        // !!! this.turn.id_people = this.$root.user.uid;

        // if (this.turn.status == TURN_FREE_VAL) {
        //   this.setFreeTurn();
        // }

        // if (this.turn.status == null) {
        //   if (this.turn.id_people) {
        //     this.turn.status = TURN_CLOSE_VAL;
        //   } else {
        //     this.setFreeTurn();
        //   }
        // }
      } else document.removeEventListener("keyup", this.keyPressHandler);
    },

    timeAddFlag: function (newVal) {
      if (!newVal) {
        this.turn.time_add = 0;
      }
    },

    "turn.id_people": function (newVal) {
      if (!this.showFlag || newVal <= 0) return;
      // console.log("turn.id_people", newVal);
      // console.log("this.showFlag", this.showFlag);
      if (this.turn.status == TURN_FREE_VAL) return;
      this.turn.id_job = this.people.find((el) => el.id == newVal).id_job;
    },

    // "turn.id_job": function (newVal) {
    //   if (this.turn.status == TURN_FREE_VAL || !newVal) return;
    //   this.turn.turn_rate = this.jobs.find((el) => el.id == newVal).job_rate;
    // },

    "turn.turn_rate": function (newVal) {
      if (isNaN(parseInt(newVal))) this.turn.turn_rate = "";
      else newVal = parseInt(newVal);
    },
  },

  methods: {
    keyPressHandler(evt) {
      // console.log("key pressed:", evt.code);
      if (evt.code == "Escape") this.windowClose(0, null);
      if (evt.code == "Enter") this.Apply();
    },

    dateFormatHuman(d, w) {
      return dateFormatHuman(d, w);
    },

    peopleSelectorHandler(id_people) {
      this.turn.id_people = id_people;
      if (this.people.length)
        this.turn.id_job = this.people.find((el) => el.id == id_people).id_job;
    },

    setFreeTurn() {
      this.turn.status = TURN_FREE_VAL;
      this.turn.id_people = null;
      this.turn.id_job = null;
      this.turn.turn_rate = null;
    },

    setCloseTurn() {
      this.turn.status = TURN_CLOSE_VAL;
    },

    async Apply() {
      // console.log("APPLY pressed");
      // console.log("this.turn.id_people", this.turn.id_people);
      // check, that human is selected
      if (
        this.turn.status != TURN_FREE_VAL &&
        (!this.turn.id_people || this.turn.id_people == -1)
      ) {
        this.$refs.human.classList.add("warn-border");
        setTimeout(() => this.$refs.human.classList.remove("warn-border"), 300);
        return;
      }

      // check for correct time interval
      if (parseInt(this.turn.hour_begin) > parseInt(this.turn.hour_end)) {
        this.$refs.hours.classList.add("warn-border");
        setTimeout(() => {
          this.$refs.hours.classList.remove("warn-border");
        }, 500);
        return;
      }

      // disable button for prevent turn dupliction
      this.$refs.applyButton.disabled = true;

      // check for intersection with other turns
      if ((await this.turnMatches()) > 0) {
        this.$refs.applyButton.classList.add("warn");
        setTimeout(() => this.$refs.applyButton.classList.remove("warn"), 300);
        this.errMessage = "пересечение с другой сменой";
        setTimeout(() => (this.errMessage = null), 1000);
        this.$refs.applyButton.disabled = false;
        return;
      }

      if (this.turn.id_turn) {
        //update record
        const res = await request("/api/turn/update", "POST", this.turn);
        this.$refs.applyButton.disabled = false;
        if (res.affectedRows) this.windowClose(this.turn, "upd");
      } else {
        // insert record
        const res = await request("/api/turn/insert", "POST", this.turn);
        this.$refs.applyButton.disabled = false;
        if (res.affectedRows == 1 && res.insertId) {
          this.turn.id_turn = res.insertId;
          this.windowClose(this.turn, "add");
        }
      }
    },

    windowClose(turnId, action) {
      this.$emit("hide", turnId, action);
    },

    async turnMatches() {
      const data = await request("/api/turn/validation", "POST", this.turn);
      return parseInt(data[0].cnt);
    },

    turnStatusRotate() {
      this.turn.status = this.turn.status == TURN_CLOSE_VAL ? TURN_FREE_VAL : TURN_CLOSE_VAL;
      if (this.turn.status == TURN_FREE_VAL) {
        this.setFreeTurn();
      } else {
        this.setCloseTurn();
      }

      // console.log(this.turn);
    },

    turnTypeRotate() {
      this.turn.turn_type =
        this.turn.turn_type == TURN_TYPE_ADVICE ? TURN_TYPE_SESSION : TURN_TYPE_ADVICE;
    },

    async turnDelete() {
      if (!confirm("Удалить сеанс?")) return;
      // DB request
      const res = await request("/api/turn/delete", "DELETE", {
        id_turn: this.turn.id_turn,
      });
      // delete fault
      if (res.affectedRows === 0) {
        // add ERROR effect !!!
        return;
      }
      this.windowClose(this.turn, "del");
    },
  },
};
</script>
