<template>
  <div>
    <template v-for="(turn, i) in TURNS.getList()">
      <template v-if="turn">
        <div
          :key="i"
          class="turn"
          :class="turn.turn_type"
          :title="
            title(
              turn.status,
              turn.id_people,
              turn.id_job,
              turn.p_daytime,
              turn.p_weekday,
              turn.p_jobtype,
              turn.p_status,
              'middle'
            )
          "
          @click.left.prevent="$emit('openEdit', turn.id_turn)"
          @click.right.prevent="captionChange(turn.id_turn)"
          :style="{
            top:
              top + turn.orderAbs * height + dateDiff(date_begin_local, turn.turn_date) + 'px',
            left:
              left + (turn.hour_begin - HOUR_START) * width + CHESSTABLE_BORDER_WIDTH + 'px',
            width:
              (turn.hour_end - turn.hour_begin + 1) * width + -CHESSTABLE_BORDER_WIDTH + 'px',
            height: height - CHESSTABLE_BORDER_WIDTH + 'px',
            backgroundColor: turn.highLight,
          }"
        >
          <div>{{ turn.caption }}{{ turn.time_add ? " +" + turn.time_add : null }}</div>
        </div>
      </template>
    </template>
  </div>
</template>
<script>
import { HOUR_START, TURN_FREE_VAL } from "@/config/settings";
import { CHESSTABLE_BORDER_WIDTH } from "@/config/design";

export default {
  name: "Turn",
  props: {
    TURNS: Object,
    people: Array,
    jobs: Array,
    // tbl: Array,
    // id_point: Number,
    // turnDel: Number,
    date_begin_local: String,
  },
  data() {
    return {
      spacer: '"\u00A0"',

      HOUR_START,
      CHESSTABLE_BORDER_WIDTH,

      top: 0,
      left: 0,
      height: 0,
      width: 0,
      widthBorder: 0,
    };
  },
  created() {
    // this for next opens
    this.redraw();
  },

  updated() {
    // this is for first open
    this.redraw();
    // console.log('updated');
  },

  methods: {
    // get days between dates
    dateDiff(date_begin, date_end) {
      return (
        (new Date(date_end).getTime() - new Date(date_begin).getTime()) / 1000 / 60 / 60 / 24
      );
    },

    title(
      status,
      id_people,
      id_job,
      p_daytime,
      p_weekday,
      p_jobtype,
      p_status,
      type = "short"
    ) {
      if (status == TURN_FREE_VAL) {
        let res = "";
        if (p_daytime) res += p_daytime;
        if (p_weekday) res += "\n" + p_weekday;
        if (p_jobtype) res += "\n" + p_jobtype;
        if (p_status) res += "\n" + p_status;
        return res ? res : "свободная\nсмена";
      }

      const ppl = this.people.find((e) => e.id == id_people);
      const jobname = this.jobs.find((e) => e.id == id_job).job_name;

      if (type === "short") return ppl.nickname ? ppl.nickname : ppl.surname + " " + ppl.name;
      if (type === "middle") return `${ppl.surname} ${ppl.name}\n${jobname}`;
      // if (type === "full")
      //   return `${ppl.surname} ${ppl.name} ${ppl.patronymic}`;

      return "error";
    },

    captionChange(id_turn) {
      // this.TURNS.rotateCaption(id_turn);
      this.TURNS.highlightGroup(id_turn);
    },

    redraw() {
      // this is for REDRAW
      if (this.$parent.$refs.chesstable_tbl && this.$parent.$refs.chesstable_cell) {
        // const rect1 = this.$parent.$refs.chesstable_tbl.getBoundingClientRect();
        const rect2 = this.$parent.$refs.chesstable_cell[0].getBoundingClientRect();

        // this.top = rect1.top + rect2.top + CHESSTABLE_BORDER_WIDTH;

        this.top =
          this.$parent.$refs.chesstable_tbl.offsetTop +
          this.$parent.$refs.chesstable_cell[0].offsetTop +
          CHESSTABLE_BORDER_WIDTH;

        this.left =
          this.$parent.$refs.chesstable_date[0].offsetWidth +
          this.$parent.$refs.chesstable_date[0].offsetParent.offsetLeft;

        // console.log("this.$parent.$refs.chesstable_date[0].offsetWidth");
        // console.log(this.$parent.$refs.chesstable_date[0].offsetWidth);
        // console.log("this.$parent.$refs.chesstable_date[0].offsetLeft");
        // console.log(this.$parent.$refs.chesstable_date[0].offsetLeft);
        // console.log(
        //   "this.$parent.$refs.chesstable_date[0].offsetParent.offsetLeft"
        // );
        // console.log(
        //   this.$parent.$refs.chesstable_date[0].offsetParent.offsetLeft
        // );

        this.height = rect2.height;

        // this.height =
        //   this.$parent.$refs.chesstable_cell[0].offsetHeight -
        //   CHESSTABLE_BORDER_WIDTH;

        this.width = rect2.width;
        // this.width = this.$parent.$refs.chesstable_cell[0].offsetWidth;

        // console.log("this.height is ", this.height);
        // console.log("this.width is ", this.width);
      }
    },
  },
};
</script>
