var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.TURNS.getList()),function(turn,i){return [(turn)?[_c('div',{key:i,staticClass:"turn",class:turn.turn_type,style:({
          top:
            _vm.top + turn.orderAbs * _vm.height + _vm.dateDiff(_vm.date_begin_local, turn.turn_date) + 'px',
          left:
            _vm.left + (turn.hour_begin - _vm.HOUR_START) * _vm.width + _vm.CHESSTABLE_BORDER_WIDTH + 'px',
          width:
            (turn.hour_end - turn.hour_begin + 1) * _vm.width + -_vm.CHESSTABLE_BORDER_WIDTH + 'px',
          height: _vm.height - _vm.CHESSTABLE_BORDER_WIDTH + 'px',
          backgroundColor: turn.highLight,
        }),attrs:{"title":_vm.title(
            turn.status,
            turn.id_people,
            turn.id_job,
            turn.p_daytime,
            turn.p_weekday,
            turn.p_jobtype,
            turn.p_status,
            'middle'
          )},on:{"click":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"])){ return null; }if('button' in $event && $event.button !== 0){ return null; }$event.preventDefault();return _vm.$emit('openEdit', turn.id_turn)},"contextmenu":function($event){$event.preventDefault();return _vm.captionChange(turn.id_turn)}}},[_c('div',[_vm._v(_vm._s(turn.caption)+_vm._s(turn.time_add ? " +" + turn.time_add : null))])])]:_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }