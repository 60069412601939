<template>
  <div class="noselect">
    <div class="heap">
      <div>
        <form v-on:submit.prevent="" class="flex">
          <div class="heap-block">
            <DateSelector label="Дата нач." :val="date_begin" @change="date_begin = $event" />
          </div>
          <div class="heap-block">
            <DateSelector label="Дата кон." :val="date_end" @change="date_end = $event" />
          </div>
          <div class="heap-block">
            <PointSelector label="Точка" :id_pointIn="id_point" @change="update($event)" />
          </div>
          <!-- <div v-if="this.$root.user.roles.includes('admin')" class="high">
            Auto
            <input type="checkbox" v-model="autoTable" />
          </div> -->
          <div class="heap-block">
            <div @click.prevent="tableDataRetrieve" class="high pointer">
              <button class="btn" ref="btn">Обновить</button>
            </div>
          </div>
        </form>
      </div>

      <!-- <div>
        <HoursRuler />
      </div> -->
    </div>

    <div v-if="loading">
      <Loader />
    </div>
    <ChessItself
      v-else
      :autoTable="autoTable"
      :tbl="tbl"
      :TURNS="TURNS"
      :id_point="id_point"
      :people="people"
      :jobs="jobs"
      :date_begin="date_begin"
    />
    <br />
    <br />
  </div>
</template>

<script>
import DateSelector from "@/components/DateSelector.vue";
import PointSelector from "@/components/PointSelector.vue";
// import HoursRuler from "@/components/HoursRuler.vue";
import ChessItself from "@/components/ChessItself.vue";
import Loader from "@/components/Loader.vue";
import { dateFormatJS } from "@/components-js/dateFormat";
import { request } from "@/components-js/requestSrv";
import { TURNS_ } from "@/components-js/turns";
import { lS } from "@/components-js/localStorage";
const loStorage = new lS();

import {
  HOUR_START,
  HOUR_END,
  DATE_DEFAULT_INTERVAL,
  DATE_MAX_INTERVAL,
} from "@/config/settings";

export default {
  components: {
    DateSelector,
    PointSelector,
    // HoursRuler,
    Loader,
    ChessItself,
  },

  data() {
    return {
      autoTable: false,
      loading: false,
      date_begin: null,
      date_end: null,
      DATE_DEFAULT_INTERVAL,
      DATE_MAX_INTERVAL,
      HOUR_START,
      HOUR_END,
      tbl: [],
      id_point: null,

      people: [],
      jobs: [],
      TURNS: null,
    };
  },

  async created() {
    //console.log(this.$root.user);
    this.TURNS = new TURNS_(this.$root.user.uid);
  },

  async mounted() {
    // get list of people
    this.people = await request("/api/user/listall", "GET");
    // get list of jobs
    this.jobs = await request("/api/job/list", "GET");

    // set default values for dates and point
    const d = new Date();
    this.date_begin = this.date_begin = dateFormatJS(d);
    this.date_end = dateFormatJS(new Date(d.setDate(d.getDate() + this.DATE_DEFAULT_INTERVAL)));

    let tmp = loStorage.getObjectProp(this.$route.path, "date_begin");
    if (tmp) this.date_begin = tmp;

    tmp = loStorage.getObjectProp(this.$route.path, "date_end");
    if (tmp) this.date_end = tmp;

    tmp = loStorage.getObjectProp(this.$route.path, "id_point");
    if (tmp) {
      this.id_point = tmp;
      this.tableDataRetrieve();
    }
  },

  beforeDestroy() {
    this.TURNS.clear();
  },

  methods: {
    update: function (id) {
      console.log("id: ", id);
      this.id_point = parseInt(id);
      this.tableDataRetrieve();
    },
    // getting data from server
    async tableDataRetrieve() {
      this.loading = true;

      // check if they are dates?
      if (!new Date(this.date_begin) || !new Date(this.date_end)) return;

      // check dates for begin lower, then end
      if (new Date(this.date_begin) > new Date(this.date_end)) this.date_end = this.date_begin;

      // check for interval is not more, than date_max_interval days
      if (
        new Date(
          new Date(this.date_begin).setDate(
            new Date(this.date_begin).getDate() + this.DATE_MAX_INTERVAL
          )
        ) < new Date(this.date_end)
      )
        this.date_end = dateFormatJS(
          new Date(
            new Date(this.date_begin).setDate(
              new Date(this.date_begin).getDate() + this.DATE_MAX_INTERVAL
            )
          )
        );

      // DB request
      let q = "/api/turn/list";
      if (this.autoTable) q = "/api/turn/listauto";

      const data = await request(
        q,
        "POST",
        {
          id_point: this.id_point,
          date_begin: this.date_begin,
          date_end: this.date_end,
        },
        this.$route.path
      );
      this.loading = false;

      // even if data array is empty, still need to show empty table

      // temporary array
      let tbl_copy = [];

      // from date_begin to date_end
      let d1 = new Date(this.date_begin);
      const d2 = new Date(this.date_end);

      let orderAbs = 0;
      let orderMax = 0;
      while (d1 <= d2) {
        // row order in a turn
        let turn_order = 0;
        let date_current = dateFormatJS(d1);
        // if there is a TURN at the date
        if (data.findIndex((item) => item.turn_date === date_current) > -1) {
          // found TURN at the date
          // remember row, where to start fill array
          let length_current = tbl_copy.length;
          orderMax = 0;
          // through all TURN array, for every row in dates array
          for (let X of data.filter((item) => item.turn_date === date_current)) {
            // get order in a turn from turn record
            turn_order = X.turn_order;

            // create new row in tbl, if not exist
            if (tbl_copy[length_current + turn_order] === undefined) {
              // fix max order
              orderMax = turn_order;
              // if we have empty row(s)
              for (let l = length_current; l <= length_current + turn_order; l++) {
                if (tbl_copy[l] === undefined) {
                  tbl_copy[l] = [];
                  for (let i = HOUR_START; i <= HOUR_END; i++) tbl_copy[l][i] = 0;
                  // prelast column is date
                  tbl_copy[l].push(date_current);
                  // last column is turn_order
                  tbl_copy[l].push(l - length_current);
                }
              }
            }

            // through hours
            for (let k = X.hour_begin; k <= X.hour_end; k++)
              // insert turn
              tbl_copy[length_current + turn_order][k] = X.id_turn;

            X.orderAbs = orderAbs + turn_order;
          }
          orderAbs += orderMax;
          orderAbs++;
        } else {
          // TURN is absent at the date, fill it with zero values
          let date_current = dateFormatJS(d1);
          // add row into tbl array
          tbl_copy[tbl_copy.length] = [];
          // increase order
          orderAbs++;
          // fill row of tbl array with zero values
          for (let k = HOUR_START; k <= HOUR_END; k++) tbl_copy[tbl_copy.length - 1][k] = 0;
          // prerlast column is date
          tbl_copy[tbl_copy.length - 1].push(date_current);
          // last column is turn_order
          tbl_copy[tbl_copy.length - 1].push(turn_order);
        }

        d1 = new Date(d1.setDate(d1.getDate() + 1));
      }
      this.TURNS.fill(data);
      this.tbl = tbl_copy;
    },
  },
};
</script>
