const CHESSTABLE_BORDER_WIDTH = 1;
const COLORS_HIGHLIGHT = [
  // "#AFB194",
  // "#BF7F3F",
  // "#93E344",
  // "#F0A2D7",
  // "#484ACB",
  // "#58BFB7",
  // "#E93E52",

  "#AFB194",
  "#BF7F3F",
  "#b363e2",
  "#b6b844",
  "#484ACB",
  "#58BFB7",
  "#E93E52",
];
const TURN_OWN_COLOR_SESSION = "#a51111";
const TURN_OWN_COLOR_ADVICE = "#a51111aa";

export {
  CHESSTABLE_BORDER_WIDTH,
  COLORS_HIGHLIGHT,
  TURN_OWN_COLOR_SESSION,
  TURN_OWN_COLOR_ADVICE,
};
