<template>
  <div class="debug">
    <a href="#" class="dbg" v-on:click="showPeople()">people[]</a>
    <a href="#" class="dbg" v-on:click="showTurns()">turns[]</a>
    <a href="#" class="dbg" v-on:click="showTbl()">tbl[]</a>
  </div>
</template>

<script>
export default {
  name: "Debug",
  props: { showPeople: Object, showTurns: Object, showTbl: Object },
};
</script>
