<template>
  <label class="modalMobile">
    {{ label }}
    <select
      @change="$emit('change', $event.target.value)"
      ref="hour"
      :value="hour"
      class="modalMobile"
    >
      <template v-for="el in HOURS">
        <option v-if="el" :value="el" :key="el">
          {{ el + parseInt(offset) == 24 ? "00" : el + parseInt(offset) }}
        </option>
      </template>
    </select>
  </label>
</template>

<script>
import { HOURS } from "@/config/settings";

export default {
  name: "HourSelector",
  props: { label: String, hour: Number, offset: Number },
  data: function () {
    return {
      HOURS,
    };
  },
};
</script>
